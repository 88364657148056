<template>
    <v-content>
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                top
        >
            {{ t(text) }}
            <v-btn
                    flat
                    @click="snackbar = false"
            >
                {{t('Close')}}
            </v-btn>
        </v-snackbar>
        <v-container
                fluid
                fill-height
        >
            <v-layout
                    align-center
                    justify-center
            >
                <v-flex
                        xs12
                        sm8
                        md4
                >
                    <v-card class="elevation-12" v-if="submitted">
                        <v-toolbar
                                color="primary"
                                dark
                                flat
                        >
                            <v-toolbar-title>Send email</v-toolbar-title>
                            <v-spacer>
                            </v-spacer>
                        </v-toolbar>
                        <v-form
                                ref="form"
                                class="text-xs-right"
                                @submit.prevent="resent"
                        >
                            <v-card-text>

                                <v-text-field
                                        v-model="email"
                                        :label="t('Email')"
                                        name="Email"
                                        prepend-icon="email"
                                        :rules="[rules.email]"
                                />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" type="submit">{{t('Send')}}</v-btn>
                            </v-card-actions>
                            <v-card-text class="d-flex justify-content-between mt-2">
                                <router-link class="text-xs-left" to="/register">{{t('Register now!')}}</router-link>
                                <router-link class="text-xs-right" to="/forgot">{{t('Forgot Password?')}}</router-link>
                            </v-card-text>
                        </v-form>
                    </v-card>
                    <v-card class="elevation-12" v-else>
                        <v-toolbar
                                color="primary"
                                dark
                                flat
                        >
                            <v-toolbar-title>{{t('Resent email')}}</v-toolbar-title>
                            <v-spacer>
                            </v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p>{{t('Please check your email')}} {{email}} {{t('to verify your account')}}</p>
                        </v-card-text>
                        <v-card-text class="d-flex justify-content-center mt-2">
                            <p class="text-xs-center">{{t('Already heave an account?')}}
                                <router-link to="/login">{{t('Login')}}</router-link>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "SendActivationEmail",
        data() {
            return {
                snackbar: false,
                timeout: 5000,
                text: '',
                submitted: true,
                email: '',
                rules: {
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
                    },
                }
            }
        },
        methods: {
            resent() {
                axios.post('/api/resent/', {email: this.email})
                .then((response) => {
                    this.submitted = false;
                    this.email = '';
                }).catch((error) => {
                    this.text = error.response.data.detail;
                    this.snackbar = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>